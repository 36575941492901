class VideoLoader < HTMLElement
  custom_elements.define "video-loader", VideoLoader

  def connected_callback()
    video_tag = document.create_element("video")
    Object.assign(video_tag,
      plays_inline: true,
      autoplay: true,
      muted: true,
      loop: true,
      src: self.get_attribute(:src)
    )
    video_gradient = document.create_element("video-gradient")

    video_tag.add_event_listener :canplay do
      set_timeout 1000 do
        self.append video_tag
        self.append video_gradient

        set_timeout 300 do
          video_gradient.style.opacity = 1
          video_tag.style.opacity = 1
        end
      end
    end
  end

  def disconnected_callback()
    self.query_selector("video").remove()
  end
end
