import Typed, from: "typed.js"

class TypedText < HTMLElement
  custom_elements.define "typed-text", TypedText

  def connected_callback()
    @typed = Typed.new(
      self,
      stringsElement: self.parent_element.query_selector("typed-text-strings"),
      typeSpeed: 60,
      backSpeed: 30,
      backDelay: 2500,
      startDelay: 500,
      loop: true
    )
  end

  def disconnected_callback()
    @typed.destroy()
  end
end
