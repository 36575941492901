import "$styles/index.css"

import '@shoelace-style/shoelace/dist/components/icon/icon.js';
import { registerIconLibrary } from '@shoelace-style/shoelace/dist/utilities/icon-library.js';

registerIconLibrary('lucide', {
  resolver: name => `https://cdn.jsdelivr.net/npm/lucide-static@0.366.0/icons/${name}.svg`
});

import Swup from "swup"
import SwupHeadPlugin from "@swup/head-plugin"
import SwupBodyClassPlugin from "@swup/body-class-plugin"
import SwupA11yPlugin from "@swup/a11y-plugin"
//import SwupScriptsPlugin from "@swup/scripts-plugin"

const swup = new Swup({
  containers: ["#swup", "#hero"],
  plugins: [
    new SwupHeadPlugin(),
    new SwupBodyClassPlugin(),
    new SwupA11yPlugin(),
  ]
})

// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css}"
